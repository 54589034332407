import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { userState, cartState, modeState, messageState, cartOpenState, sessionState, pageviewState } from '../../atoms';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { makeRequest, isPreorder, isAvailableSoon, returnPrice, getInventory, canOrderProduct, getEffectiveInventory, prettyIsbn, wishlistChange, analyticsEvent, isDigitalItem, formatNiceName, updatedCart, asaEvent } from '../../Utils';
import moment from 'moment';
import ProductListItem from '../../components/ProductListItem';
import PreviewModal from '../../components/PreviewModal';
import './style.css';
import showdown from 'showdown';
import GhostGrid from '../../components/GhostGrid';
import BookIcon from '../../icons/book';
import { returnStructuredData } from './structuredData';
import { GhostImage, GhostText } from '../../components/GhostProduct';
const converter = new showdown.Converter();


export default function Product() {
  const [product, setProduct] = useState({});
  const [cart, setCart] = useRecoilState(cartState);
  const setCartOpen = useSetRecoilState(cartOpenState);
  const [inventory, setInventory] = useState(0);
  const user = useRecoilValue(userState);
  const mode = useRecoilValue(modeState);
  const [contribs, setContribs] = useState([]);
  const [price, setPrice] = useState(0);
  const [related, setRelated] = useState([]);
  const setMessage = useSetRecoilState(messageState);
  const [preview, setPreview] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [canOrder, setCanOrder] = useState(true);
  const [digital, setDigital] = useState(false);
  const [variants, setVariants] = useState([]);
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const { slug } = useParams();

  const getRelated = async (id) => {
    let data = await makeRequest(`related-products?id=${id}&group=${session.group}`, 'GET');
    setRelated(data);
  }

  const getVariants = async (id) => {
    let v = await makeRequest(`variants?id=${id}`);
    setVariants(v);
  }

  const checkParams = () => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('preview') === 'true') {
      setPreview(true);
    }

    if (params.get('addToCart') === 'true') {
      let qty = params.get('qty') ? parseInt(params.get('qty')) : 1;
      addToCart(qty, true);
      params.delete('addToCart');
      params.delete('qty');
    }
  }

  const getProduct = async (slug) => {
    setRelated([]);
    let data = await makeRequest(`product-by-slug?slug=${slug}`, 'GET');
    if (!data._id) {
      navigate('/404');
    }
    document.title = `${data.title} from ${data.publisherData.name} | Asterism Books`;
    document.querySelector('meta[name="description"]').setAttribute('content', `${data.description}`);

    setProduct(data);
    setLoading(false);
    setInventory(getInventory(data));
    getRelated(data._id);
    getVariants(data._id);
  }

  useEffect(() => {
    if (!product._id) return;
    setPrice(returnPrice(product, preview ? 'retail' : mode));
  }, [product, mode])

  useEffect(() => {
    if (slug) {
      setProduct({});
      getProduct(slug);
    }
  }, [slug]);

  useEffect(() => {
    if (product.title) {
      checkParams();
    }
    if (product.authors) {
      prepareProductPeople();
    }
    if (product.format) {
      setDigital(isDigitalItem(product.format));
    }
  }, [product]);

  useEffect(() => {
    if (!product._id) {
      return;
    }
    setCanOrder(canOrderProduct(product, mode));
  }, [product, mode])



  const isOnSale = () => {
    return mode === 'retail' && product.prices.retailDiscount;
  }

  const prepareProductPeople = () => {
    let people = [];
    product.translators.forEach((person) => {
      people.push({
        name: person,
        role: 'translator'
      })
    });
    product.editors.forEach((person) => {
      people.push({
        name: person,
        role: 'editor'
      })
    });
    product.illustrators.forEach((person) => {
      people.push({
        name: person,
        role: 'illustrator'
      })
    });
    product.artists.forEach((person) => {
      people.push({
        name: person,
        role: 'artist'
      })
    });
    if (product.contributors) {
      product.contributors.forEach((person) => {
        people.push({
          name: person,
          role: 'contributor'
        })
      });
    }
    setContribs(people);
  }

  const addToCart = (qty = 1, open = false, used = false) => {
    setCart(updatedCart(cart, product, qty, used));
    if (open) {
      setCartOpen(true);
    } else {
      setMessage({ type: 'success', label: 'Added to Cart', text: `<strong>${product.title}</strong> has been added to your cart!`, img: product.cover['300'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com'), temp: true, cart: true })
    }
    analyticsEvent('addToCart', { product: product._id, location: 'productPage', mode: mode });
    asaEvent({
      session: session.id,
      category: 'cart',
      action: 'addToCart',
      label: product._id,
      locationOne: '',
      locationTwo: 'productAvailability',
      locationThree: `button_${isPreorder(product) ? 'preorder' : 'add'}`,
      testgroup: session.group,
      pagetype: pageview.pagetype,
      pageview: pageview.id,
      campaign: session.campaign,
      value: qty
    });
  }

  const returnProductDescription = () => {
    let html = converter.makeHtml(product.description);
    let externalized = html.replace(/<a/g, '<a target="_blank"');
    return externalized;
  }

  if (!product.title) {
    return (
      <div>
        <div className='flex-layout flexColMobile'>
          <div className='flex-two'>
            <div className='productPageImage'>
              <GhostImage width='100%' />
            </div>
          </div>
          <div className='flex-four'>
            <div className='productPageHeader'>
              <div className='productPageHeader__stats'>
                <GhostText height='14px' min={42} max={52} />
              </div>
              <h1 className='productPageHeader__title'><GhostText height='42px' /></h1>
              <div className='productPageHeader__authors'>
                <GhostText height='16px' />
              </div>
            </div>
            <div className='roomAboveLarge'>
              <GhostText height='16px' rows={12} />
            </div>
          </div>
        </div>
        <div className=''>
          <h3>Related Products</h3>
          <div className='lowerGrid'>
            {related.length === 0 && <GhostGrid />}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='flex-layout flexColMobile'>
        <div className='flex-two'>
          <div className='productPageImage'>
            {product.cover && product.cover['900'] ? <img src={product.cover['900'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com')} alt={`${product.title} book cover`} />
              : <BookIcon />}
          </div>
          {product.preview && <button className='previewButton' onClick={() => { setPreviewing(true) }}><i className='fa-solid fa-eye'></i> Preview This Title</button>}
          {previewing && <PreviewModal product={product} close={() => { setPreviewing(false) }} />}
        </div>
        <div className='flex-four'>
          <div className='productPageHeader'>
            <div className='productPageHeader__stats'>
              <span>{moment(product.publicationDate).format('LL')} | </span>
              <span><Link className='productPageHeader__publisher' to={`/publisher/${product.publisherData.slug}`}>{product.publisherData.name}</Link> | </span>
              <span>{prettyIsbn(product._id)}</span>
              {product.seriesNo && <span> | No. {product.seriesNo} in {product.series}</span>}
              {(product.publisher === user.id || user.admin) && !preview && <span> | <Link to={`/pub/product/${product._id}`}>Edit</Link></span>}
            </div>
            <h1 className='productPageHeader__title'>{product.title}</h1>
            {product.subtitle && <div className='productPageHeader__subtitle'>{product.subtitle}</div>}
            <div className='productPageHeader__authors'>
              {product.authors.map((user, index) =>
                <span key={user} className='productPageHeader__author'>{user}{index !== product.authors.length - 1 && ', '}</span>
              )}

              {product.authors.length > 0 && contribs.length > 0 && <span> | </span>}

              {contribs.map((person, index) =>
                <span key={user.name}>{person.name} ({person.role}){index !== contribs.length - 1 && ', '}</span>
              )}

            </div>
          </div>


          <div className='productAvailability'>

            <div className='productPContainer'>
              {price !== product.prices.retail && <span className='product__list'>${product.prices.retail.toFixed(2)}</span>}
              <span className='productPrice'>${price.toFixed(2)}</span>
              {isOnSale() ? <span className='OnSaleBanner'>{product.prices.retailDiscount}% off</span> : <></>}
            </div>

            <div className='productAvailability__control'>
              {canOrder && <button className={`buttonPrimary buttonPrimary-red`} onClick={() => { addToCart(1) }}>{isPreorder(product) ? 'Pre-order' : 'Add to Cart'}</button>}
              {!canOrder && <div className='outOfStock'>Out of Stock</div>}
              <button onClick={() => {
                asaEvent({
                  session: session.id,
                  category: 'wishlist',
                  action: 'click',
                  label: product._id,
                  locationOne: '',
                  locationTwo: '',
                  locationThree: 'button',
                  testgroup: session.group,
                  pagetype: pageview.pagetype,
                  pageview: pageview.id,
                  campaign: session.campaign,
                  value: 1
                });
                if (user.id) {
                  wishlistChange(user.id, product._id, true);
                  setMessage({ type: 'success', label: 'Added to Wishlist', text: `<strong>${product.title}</strong> has been added to your wishlist!`, temp: true })
                } else {
                  navigate(`/login?wishlist=${product._id}`);
                }
              }} className='roomLeftMedium buttonSecondary buttonSecondary-red'><i className='fa-solid fa-bookmark'></i> <span className='mobHide'>Add to Wishlist</span></button>
            </div>

          </div>

          {/* Exp Used Sale */}
          {product.inventory.damaged &&
            <div className='usedSale'>
              <div className='usedHeader'>Or buy it used!</div>
              <div className='usedMessage'>We have {product.inventory.damaged} used {product.inventory.damaged === 1 ? 'copy' : 'copies'} in stock. Sometimes we receive books that have light scuffing, scrunching, or scratching, but are perfectly readable!</div>
              <button className='usedBuy' onClick={() => {
                addToCart(1, false, true);
              }}>Buy Used for ${returnPrice(product, 'retail', null, false, true).toFixed(2)}</button>
            </div>
          }

          {mode === 'retail' && product.pubLink && product.pubLink.length > 0 &&
            <div className='productAvailability__pubLink'>
              <a target='_blank' rel="noreferrer" onClick={() => {
                asaEvent({
                  session: session.id,
                  category: 'offsite',
                  action: 'click',
                  label: product._id,
                  locationOne: '',
                  locationTwo: '',
                  locationThree: 'publisherLink',
                  testgroup: session.group,
                  pagetype: pageview.pagetype,
                  pageview: pageview.id,
                  campaign: session.campaign,
                  value: 1
                });
              }} href={product.pubLink}>Or buy this book directly from {product.publisherData.name} <i className='fa-solid fa-arrow-up-right-from-square'></i></a>
            </div>}
          {mode === 'wholesale' &&
            <div className='productAvailability__inventory'>
              {inventory > 0 && <span>{inventory} in stock now.</span>}
              {getEffectiveInventory(product, mode) <= 0 && <span>Product not available.</span>}
              {isPreorder(product) && canOrder && <span>Product is available to pre-order.</span>}
              {!isPreorder(product) && canOrder && product.inventory.backorderEnabled && !product.inventory.printOnDemand && <span>Product can be backordered.</span>}
            </div>
          }
          {product.inventory.asterism === 0 && isAvailableSoon(product) &&
            <div className='availabilityNotice'>Product will be available to ship soon. Expected ship date on or before {moment(product.availableDate).format('LL')}</div>
          }

          {variants && variants.length > 0 &&
            <div>
              <h4 className='variantsHeader'>This product has multiple versions</h4>
              <div className='variants'>
                <div className='variant current'>
                  <div className='variantTitle'>{formatNiceName(product.format)}</div>
                  <div className='variantPrice'>${returnPrice(product, mode).toFixed(2)}</div>
                </div>
                {variants.map((variant) =>
                  <Link to={`/product/${variant.slug}`}>
                    <div className='variant'>
                      <div className='variantTitle'>{formatNiceName(variant.format)}</div>
                      <div className='variantPrice'>${returnPrice(variant, mode).toFixed(2)}</div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          }

          <div className='productDescription' dangerouslySetInnerHTML={{ __html: returnProductDescription() }}></div>

          {product.awards && <div className=''>
            <h3>Awards & Nominations</h3>
            {product.awards.map((award, index) =>
              <div key={'award' + index}>
                {award.result === 'Winner' ? <span className='awardWinner awardNominee'><i className='fa-solid fa-trophy'></i></span> : <span className='awardNominee'><i className='fa-solid fa-award'></i></span>}
                <strong>{award.year} {award.award} {award.category && `(${award.category})`}</strong> / {award.result}
              </div>
            )}
          </div>}

          <div className='productStats'>
            <div className='productStat'>
              <div className='productStat__label'>Format</div>
              <div className='productStat__data'>{formatNiceName(product.format)}</div>
            </div>
            {product.pages && <div className='productStat'>
              <div className='productStat__label'>Pages</div>
              <div className='productStat__data'>{product.pages}</div>
            </div>}
            {!digital && <div className='productStat'>
              <div className='productStat__label'>Dimensions</div>
              <div className='productStat__data'>{product.dimensions.height}"x{product.dimensions.width}"x{product.dimensions.thickness}"</div>
            </div>}
            {!digital && <div className='productStat'>
              <div className='productStat__label'>Weight</div>
              <div className='productStat__data'>{product.dimensions.weight} oz.</div>
            </div>}
          </div>
          <div className='categoryList'>
            {product.categories.filter((choice) => { return choice !== null && choice.value && choice.label; }).map((category) =>
              <Link className='categoryLink' key={category.value} to={`/all-books?categories=${category.value}`}>{category.label}</Link>
            )}
          </div>
        </div>
      </div>
      <div className=''>
        <h3>Related Products</h3>
        <div className='lowerGrid'>
          {related.length === 0 && <GhostGrid />}
          {related.map((prod) =>
            <ProductListItem key={prod._id} product={prod} context={'related'} />
          )}
        </div>
      </div>
      <script type="application/ld+json">{returnStructuredData(product)}</script>
    </div>
  )
}
