import React, { useEffect, useState, useRef } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { userState, cartState, cartOpenState, cartTotalState, cartCountState, couponState, cartWeightOuncesState, modeState, messageState, checkoutMode, payPalCheckedOutState, sessionState, pageviewState, isJState } from '../../atoms/';
import { makeRequest, returnPrice, validateAddress, validateEmail, getEffectiveInventory, analyticsEvent, getProduct, isDigitalItem, asaEvent, wishlistChange, isPreorder } from '../../Utils';
import { TextControl, BinaryControl, CustomerControl, DateControl } from '../InputControls';
import { SearchControl } from '../Search';
import AddressForm from '../AddressForm';
import LoginForm from '../LoginForm';
import { prepareShipmentsAndSplits, prepareItems, refreshCartData, isCartAllDigital, isCartAnyDigital, hasGiftCard } from './cartUtils';
import { defaultOrder } from '../../Defaults';
//import PayPal from './PayPal';

import './style.css';
import GiftCardCheck from './giftCard';
import moment from 'moment';
import ShippingSelector from '../ShippingPicker';

export default function Cart({ open = false }) {
  const user = useRecoilValue(userState);
  const [cart, setCart] = useRecoilState(cartState);
  const total = useRecoilValue(cartTotalState);
  const cartCount = useRecoilValue(cartCountState);
  const ounces = useRecoilValue(cartWeightOuncesState);
  const mode = useRecoilValue(modeState);
  const setMessage = useSetRecoilState(messageState);
  const [uploading, setUploading] = useState(false);
  const [cartOpen, setCartOpen] = useRecoilState(cartOpenState);
  const [loggingIn, setLoggingIn] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [packages, setPackages] = useState(1);
  const [latestShip, setLatestShip] = useState('');
  const [consolidateShipments, setConsolidateShipments] = useState(false);
  const [collect, setCollect] = useState(true);
  const [ref, setRef] = useState('');
  const [phone, setPhone] = useState('');
  const [fetching, setFetching] = useState(false);
  const [customer, setCustomer] = useState('');
  const [customerData, setCustomerData] = useState({});
  const [coupon, setCoupon] = useRecoilState(couponState);
  const [code, setCode] = useState('');
  const [codeStatus, setCodeStatus] = useState('');
  const [freeShipping, setFreeShipping] = useState(false);
  const checkoutState = useRecoilValue(checkoutMode);
  const [newId, setNewId] = useState('');
  const [address, setAddress] = useState({
    country: 'US'
  });
  const [allDigital, setAllDigital] = useState(false);
  const [payPalId, setPayPalId] = useRecoilState(payPalCheckedOutState);
  const [preparedItems, setPreparedItems] = useState({});
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilState(pageviewState);
  const [giftOrder, setGiftOrder] = useState(false);
  const [giftCardBalance, setGiftCardBalance] = useState(0);
  const [eventOrder, setEventOrder] = useState(false);
  const [eventDate, setEventDate] = useState('');
  const debounceDelay = 750;
  const debounceTimer = useRef(0);
  const controller = useRef();
  const isJosh = useRecoilValue(isJState);
  const [gotDefaultAddress, setGotDefaultAddress] = useState(false);
  const [inPerson, setInPerson] = useState(false);
  const [rates, setRates] = useState([]);
  const [selectedRate, setSelectedRate] = useState({ asterismRate: 0 });
  const [hasRefreshed, setHasRefreshed] = useState('');

  const getDateDelay = () => {
    let day = new Date().getDay();
    return day === 5 ? 4 : day === 6 ? 3 : day === 0 ? 2 : 1;
  }

  useEffect(() => {
    let items = prepareItems(cart, customerData.type ? returnUserType(customerData) : mode, null, false, coupon);
    setPreparedItems(items);
  }, [customerData, mode, coupon, cart]);

  const navigate = useNavigate();

  /**
   * Gets the customer info if we have a logged in user with an ID
   */
  const getCustomer = async () => {
    let customerInfo = await makeRequest(`customer?id=${customer}`, 'GET');
    if (!customerInfo.error) {
      setCustomerData(customerInfo);
      setEmail(customerInfo.email);
      setCollect(false);
      setAddress(customerInfo.address);
      setPhone(customerInfo.contact.phone);
    }
  }

  useEffect(() => {
    if (customer) {
      getCustomer();
    }
  }, [customer])

  const location = useLocation();

  useEffect(() => {
    setCartOpen(false);
    if (cartOpen) {
      asaEvent({
        session: session.id,
        category: 'cart',
        action: 'cartClose',
        label: 'navigated',
        locationOne: '',
        locationTwo: '',
        locationThree: '',
        testgroup: session.group,
        pagetype: pageview.pagetype,
        pageview: pageview.id,
        campaign: session.campaign,
      });
    }
  }, [location])

  /**
   * Removes an item from the cart
   * @param {string} id 
   */
  const removeItem = (id) => {
    let items = { ...cart };
    delete items[id];
    setCart(items);
  }

  const updateQuantity = (id, quantity) => {
    let items = { ...cart };
    let item = { ...items[id] };
    item.count = quantity;
    items[id] = item;
    setCart(items);
  }

  const updateCartData = async () => {
    let updated = await refreshCartData(cart, mode, user.admin);
    setCart(updated);
    setHasRefreshed(moment().toISOString());
  }

  useEffect(() => {
    if (cartOpen) {
      analyticsEvent('cartOpen', { mode: mode })
      if (!gotDefaultAddress) {
        getDefaultAddress();
      }
      if (!hasRefreshed || moment().diff(moment(hasRefreshed), 'minutes') > 60) {
        updateCartData();
      }
    }
  }, [cartOpen]);

  useEffect(() => {
    if (payPalId) {
      checkout(payPalId);
    }
  }, [payPalId]);

  const isFreeShipping = () => {
    let free = false;
    if (allDigital) {
      free = true;
    } else if ((mode === 'wholesale' || user.admin) && (address.state && (address.state?.toLowerCase().trim() === 'wa' || address.state?.trim().toLowerCase() === 'washington')) && (address.city.toLowerCase() === 'seattle') && address.country === 'US') {
      free = true;
    } else if (freeShipping) {
      free = true;
    }
    return free;
  }

  const getShipping = async () => {
    // Check for local and free!
    clearTimeout(debounceTimer.current);
    if (ounces <= 0 || !address.postalCode || !address.country) return;
    if (isFreeShipping()) {
      setRates([{ carrier: '', service: 'Free shipping', asterismRate: 0, delivery_days: 4 }]);
      setSelectedRate({ carrier: '', service: 'Free shipping', asterismRate: 0, delivery_days: 4 });
      return;
    }
    if (address.country === 'US' && address.postalCode.length < 5) return;
    setFetching(true);
    let request = {
      ounces: ounces,
      country: address.country,
      postalCode: address.postalCode,
      free: isFreeShipping(),
      digital: allDigital,
      items: cartCount,
      value: getCartTotal()
    }
    controller.current?.abort();
    controller.current = new AbortController();

    debounceTimer.current = setTimeout(async () => {
      let theRates = await makeRequest('new-shipping-rates', 'POST', request, false, { signal: controller.current.signal });
      let delay = getDateDelay();
      theRates = theRates.map((rate) => {
        rate.delivery_days += delay;
        return rate;
      })
      //
      if (packages > 1 && !consolidateShipments) {
        let extra = packages - 1;
        let multiple = address.country === 'US' ? 4 : 10;
        theRates.map((rate) => {
          rate.asterismRate += (extra * multiple);
          return rate;
        })
      }
      //
      setRates(theRates)
      if (theRates.length !== 0) {
        setSelectedRate(theRates[0]);
      }
      setFetching(false);
    }, debounceDelay)
  }

  useEffect(() => {
    if (cartOpen) {
      getShipping();
    }
  }, [ounces, address.country, address.postalCode, packages, consolidateShipments, freeShipping,])

  useEffect(() => {
    if (loaded) {
      localStorage.setItem('asterismTwoCart', JSON.stringify({ ...cart }));
    }
    if (mode !== 'wholesale' && address.country !== 'US') {
      setPackages(1); // No one can afford a bunch of small packages overseas!
      setLatestShip('')
    } else {
      const orderData = prepareShipmentsAndSplits(cartCount, cart, selectedRate.asterismRate, ounces, customerData.type ? returnUserType(customerData) : mode, false, 'US', coupon, collect);
      setPackages(orderData.shipments.length);
      setLatestShip(orderData.latestShipping);
    }
    setAllDigital(isCartAllDigital(cart));
  }, [cart]);

  useEffect(() => {
    let data = localStorage.getItem('asterismTwoCart');
    if (data && JSON.parse(data)) {
      setCart(JSON.parse(data));
    }
    setLoaded(true);
  }, []);

  /**
   * Gets the user's saved address
   */
  const getDefaultAddress = async () => {
    const data = await makeRequest('address', 'GET');
    if (data.address) {
      setAddress(data.address);
    }
    setGotDefaultAddress(true);
  }

  useEffect(() => {
    if (user.id) {
      setLoggingIn(false);
      setEmail(user.email);
    }
  }, [user]);

  /**
   * Are we okay to check out? Has the user provided all the info we need?
   * @returns {boolean} all good or not?
   */
  const checkoutCheck = () => {
    let emailValid = validateEmail(email);
    let addressValid = validateAddress(address);
    if (!emailValid) {
      setMessage({ type: 'error', label: 'Oops!', text: 'You must enter a valid email', temp: true });
      return false;
    } else if (!allDigital && !addressValid) {
      setMessage({ type: 'error', label: 'Oops!', text: 'You must complete the address form', temp: true });
      return false;
    } else if (!allDigital && address.country !== 'US' && address.country !== '' && !phone) {
      setMessage({ type: 'error', label: 'Oops!', text: 'We require a phone number for international orders', temp: true });
      return false;
    } else if (!allDigital && address.name.split(' ').length < 2) {
      setMessage({ type: 'error', label: 'Oops!', text: 'We require a first and last name for all shipments', temp: true });
      return false;
    }
    return true;
  }

  /**
   * Returns the user type for the order
   * @param {object} user is an object of user data
   * @returns {string} representing the type of user
   */
  const returnUserType = (user) => {
    return user.type === 'Retailer' ? 'wholesale' : user.type === 'Library' ? 'library' : user.type === 'Publisher' ? 'publisher' : 'retail';
  }

  const checkCoupon = async () => {
    setCodeStatus('');
    let data = await makeRequest(`coupon?code=${code}`);
    if (data.invalid) {
      setCodeStatus('invalid');
    } else {
      setCodeStatus('success');
      setCoupon(data);
    }
  }

  /** 
   * Gets the total cost minus shipping for the cart
   */
  const getCartTotal = () => {
    if (!Object.values(cart)) {
      return 0;
    }
    let theTotal = 0;
    Object.values(cart).forEach((item) => {
      let unitPrice = returnPrice(item.data, customerData.type ? 'wholesale' : mode, coupon, false, item.isUsed);
      theTotal += (unitPrice * item.count)
    });
    return theTotal;
  }

  /**
   * The checkout function!
  */
  const checkout = async (payPalData = false) => {
    setUploading(true);
    if (!payPalData && collect) {
      setMessage({ type: 'info', text: 'You will be redirected to your checkout page momentarily.', label: 'Hang on!', temp: true })
    }
    // Check if the user has done all the stuff we need them to do
    let checks = checkoutCheck();
    if (!checks) {
      setUploading(false);
      analyticsEvent('checkoutCheckFail', { mode: mode });
      asaEvent({
        session: session.id,
        category: 'checkout',
        action: 'fail',
        label: 'failedChecks',
        locationOne: '',
        locationTwo: '',
        locationThree: '',
        testgroup: session.group,
        pagetype: pageview.pagetype,
        pageview: pageview.id,
        campaign: session.campaign,
      });
      return;
    }
    // Prepare the big old order object
    let order = { ...defaultOrder };
    order.customer.id = customerData._id ? customerData._id : user.id ? user.id : '';
    order.customer.type = customerData.type ? customerData.type : user.type ? user.type : 'Guest';
    order.customer.email = email;
    order.customer.phone = phone;
    order.customer.address = address;
    order.eventOrder = eventOrder;
    order.eventDate = eventDate;
    if (payPalData) {
      order.payPalOrderId = payPalData;
    }
    order.subtotal = getCartTotal();
    order.total = getCartTotal() + selectedRate.asterismRate;
    order.hasDigital = isCartAnyDigital(cart);
    order.itemCount = cartCount;
    order.created = new Date().toISOString();
    order.type = customerData.type ? returnUserType(customerData) : mode;
    order.reference = ref;
    order.analyticsSession = session.id;
    order.testgroup = session.group;
    order.prepayDiscount = (mode === 'wholesale' || mode === 'library' || isJosh) && collect;
    if (order.prepayDiscount) {
      order.subtotal = order.subtotal * .92;
      order.total = order.subtotal + selectedRate.asterismRate;
    }
    // Prepare all of the packages we're gonna need to ship
    const orderData = prepareShipmentsAndSplits(order.total, cart, selectedRate.asterismRate, ounces, customerData.type ? returnUserType(customerData) : mode, consolidateShipments, order.customer.address.country, coupon, collect);

    order.shipments = orderData.shipments;
    order.coupon = coupon && coupon._id ? coupon._id : '';
    order.payments = orderData.payments;
    order.isGift = giftOrder;
    order.inPerson = inPerson;
    order.shippingSplit = orderData.shippingSplit;
    order.entities = Object.keys(order.payments);
    order.shipping.amount = selectedRate.asterismRate;
    order.shipping.service = selectedRate.service;
    order.shipping.method = selectedRate.carrier + ' - ' + selectedRate.service;
    order.shipping.carrier = selectedRate.carrier;
    order.shipping.consolidate = consolidateShipments;
    // Prepare the data of the items in the cart
    order.items = prepareItems(cart, customerData.type ? returnUserType(customerData) : mode, null, order.prepayDiscount, coupon);
    let data = {
      order: order, // Order data
      createAccount: creatingAccount, // Are we creating an account?
      currentPage: window.location.href, // We pass this to Stripe in case the user cancels
      pass: pass, // If the user is creating an account, their password
      collect: (mode === 'wholesale' || customerData._id || mode === 'library') ? collect : true, // Whether or not we need a checkout page
    }
    let checkoutData = await makeRequest('checkout', 'POST', data); // Make the request!
    if (checkoutData.url) {
      analyticsEvent('checkoutClick', { mode: mode });
      asaEvent({
        session: session.id,
        category: 'checkout',
        action: 'click',
        label: '',
        locationOne: '',
        locationTwo: '',
        locationThree: '',
        testgroup: session.group,
        pagetype: pageview.pagetype,
        pageview: pageview.id,
        campaign: session.campaign,
      });
      window.location.href = checkoutData.url;
    } else if (checkoutData.ok) {
      setCart({});
      setCartOpen(false);
      setMessage({ type: 'success', label: 'Thank you', temp: true, text: `Thanks! You will receive ${payPalData ? 'a receipt' : 'an invoice'} for your order` });
      setUploading(false);
      setPayPalId(false);
      analyticsEvent('checkoutComplete', { mode: mode });
      asaEvent({
        session: session.id,
        category: 'checkout',
        action: 'complete',
        label: 'nonRetailOrder',
        locationOne: '',
        locationTwo: '',
        locationThree: '',
        testgroup: session.group,
        pagetype: pageview.pagetype,
        pageview: pageview.id,
        campaign: session.campaign,
      });
    } else {
      analyticsEvent('checkoutError', { mode: mode });
      asaEvent({
        session: session.id,
        category: 'checkout',
        action: 'error',
        label: '',
        locationOne: '',
        locationTwo: '',
        locationThree: '',
        testgroup: session.group,
        pagetype: pageview.pagetype,
        pageview: pageview.id,
        campaign: session.campaign,
      });
    }

    setUploading(false);
  }

  const replicateCart = async () => {
    let data = await makeRequest(`user-cart?id=${customer}`);
    if (data.cart) {
      setCart(data.cart);
    }
  }

  /**
   * Adds an item to the cart from an ID
   * @param {string} id the id of a product
   */
  const addItemToCart = async (id) => {
    let data = await getProduct(id);
    if (data && data.prices) {
      let items = { ...cart };
      items[id] = {
        count: 1,
        data: data
      }
      setCart(items);
      setNewId('');
      searchRef.current.focus();
    }
  }

  useEffect(() => {
    if (newId) {
      addItemToCart(newId);
    }
  }, [newId]);

  useEffect(() => {
    if (eventOrder && collect) {
      setCollect(false);
    }
  }, [eventOrder]);

  useEffect(() => {
    if (coupon && coupon.discountType === 'shipping' && rates[0] && rates[0].asterismRate !== 0) {
      let newRates = [...rates];
      newRates[0].asterismRate = 0;
      setRates(newRates);
    }
  }, [coupon, rates])

  const searchRef = useRef(null);

  return (
    <div className={`headerCart ${open ? 'headerCart__open' : ''}`}>
      <div className='flex-layout cartHeader'>
        <h4 className='cartHeader__title'>In Your Cart</h4>
        <div>
          <button aria-label='Hide shopping cart' className='cartHideButton' onClick={() => { setCartOpen(false); asaEvent(session.id, session.group, 'cart', 'cartClose', 'cartButton', 1); }}>Hide <i className='fa-solid fa-arrow-right'></i></button>
        </div>
      </div>
      {checkoutState === 'admin' &&
        <div className='roomBelowMedium roomAboveLarge'><SearchControl searchref={searchRef} text='select' eagerSendId={true} selected={[]} classList={'larger'} addNew={(value) => { setNewId(typeof value == 'string' ? value : value.asterismId) }} />
        </div>
      }
      <div className='headerCartLabels'>
        <div className='headerCartItem__image'></div>
        <div className='headerCartLabel headerCartLabel-two'>Title</div>
        <div className='headerCartLabel'>Quantity</div>
        <div className='headerCartLabel'>Item Price</div>
        <div className='headerCartLabel'>Total</div>
      </div>
      <div className='headerCartList'>
        {Object.values(cart).map((item) =>
          <div key={item.data._id} className='headerCartItem'>
            <div className='headerCartItem__image'>
              {item.data.cover && <img src={item.data.cover['300']} alt={`${item.data.title}`} />}
            </div>
            <div className={'headerCartItem__title'}>
              <div><Link to={`/product/${item.data.slug}`}>{item.data.title}</Link>{item.isUsed && <div className='usedTag'>Used</div>} {isPreorder(item.data) && <div className='stockTag'>Pre-order</div>}</div>
              <div className='headerCartItem__small'>{item.data.authors.length === 1 ? item.data.authors[0] : item.data.authors.length > 1 ? `${item.data.authors[0]}, et al` : ''}</div>
              <div className='headerItemOptions'>
                <button onClick={() => {
                  asaEvent({
                    session: session.id,
                    category: 'wishlist',
                    action: 'click',
                    label: item.data._id,
                    locationOne: 'cart',
                    locationTwo: '',
                    locationThree: 'button',
                    testgroup: session.group,
                    pagetype: pageview.pagetype,
                    pageview: pageview.id,
                    campaign: session.campaign,
                    value: 1
                  });
                  if (user.id) {
                    wishlistChange(user.id, item.data._id, true);
                    setMessage({ type: 'success', label: 'Added to Wishlist', text: `<strong>${item.data.title}</strong> has been added to your wishlist!`, temp: true })
                  } else {
                    navigate(`/login?wishlist=${item.data._id}`);
                  }
                }} className=''>Save for Later</button> | <button aria-label='Remove item from cart' disabled={isCheckingOut} className='cartOptionButton' onClick={() => { removeItem(item.data._id) }}>Remove</button>
              </div>

            </div>
            <div>
              <div className='cartCounterContainer'>
                <div className={'cartCountControl'}>
                  <button disabled={item.count === 1} onClick={() => {
                    updateQuantity(item.data._id, item.count - 1)
                  }}>-</button>
                  <div className='inlineCartCount'>{item.count}</div>
                  <button disabled={(item.isUsed ? item.data.inventory.damaged : item.data.format && isDigitalItem(item.data.format) ? 1 : getEffectiveInventory(item.data, mode)) === item.count} onClick={() => {
                    updateQuantity(item.data._id, item.count + 1)
                  }}>+</button>
                </div>
              </div>
              {!isDigitalItem(item.data.format) && item.count >= getEffectiveInventory(item.data, mode) && mode !== 'wholesal' && <div className='stockTip'>Max {getEffectiveInventory(item.data, mode)} available</div>}
              {mode === 'wholesale' &&
                <div className='stockTip'>
                  {item.data.inventory.asterism === 0 && !isPreorder(item.data) ? <span>Backorder</span> : isPreorder(item.data) && item.data.inventory.asterism === 0 ? <span>Pre-order</span> : <span>{item.data.inventory.asterism} in-stock</span>}
                </div>}

            </div>
            <div className={'headerCartItem__field'}>
              ${returnPrice(item.data, customerData.type && customerData.type === 'Library' ? 'library' : customerData.type ? 'wholesale' : mode, coupon, (mode === 'wholesale' || isJosh) && collect, item.isUsed).toFixed(2)}

              {returnPrice(item.data, customerData.type && customerData.type === 'Library' ? 'library' : customerData.type ? 'wholesale' : mode, coupon, (mode === 'wholesale' || isJosh) && collect) < item.data.prices.retail &&
                <span className='headerCartItem__discount'>
                  -{Math.round((item.data.prices.retail - returnPrice(item.data, customerData.type && customerData.type === 'Library' ? 'library' : customerData.type ? 'wholesale' : mode, coupon, (mode === 'wholesale' || isJosh) && collect)) / item.data.prices.retail * 100)}%
                </span>
              }

            </div>
            <div className={'headerCartItem__field'}>
              ${(returnPrice(item.data, customerData.type && customerData.type === 'Library' ? 'library' : customerData.type ? 'wholesale' : mode, coupon, (mode === 'wholesale' || isJosh) && collect, item.isUsed) * item.count).toFixed(2)}
            </div>
          </div>
        )}
      </div>

      {total <= 0 &&
        <div className='cartEmpty'><em>You have nothing in your cart.</em></div>
      }

      {total > 0 && !isCheckingOut &&
        <div>
          {user.admin &&
            <div>
              <CustomerControl
                value={customer}
                change={setCustomer}
                label={'Customer'}
                help={''}
              />
              {customer &&
                <div className='roomBelowMedium'>
                  <button className='buttonPrimary buttonPrimary-small' onClick={replicateCart}>Replicate Cart</button>
                </div>}
            </div>
          }
          {user.admin &&
            <BinaryControl
              value={freeShipping}
              name={'free-shipping'}
              change={setFreeShipping}
              label={'Free/Third-Party Shipping'}
              disabled={uploading}
            />
          }
          {!loggingIn &&
            <div>
              <TextControl
                value={email}
                name={'Email'}
                change={setEmail}
                label={'Email'}
                valid={false}
                bypass={true}
                type='text'
                disabled={uploading || user.email}
              />
              {!user.id && <div className='cartHelpText'>Already have an account? <a role='button' tabIndex={0} onClick={() => { setLoggingIn(true) }}>Login</a></div>}

              {!user.id &&
                <div>
                  <BinaryControl
                    value={creatingAccount}
                    name={'createAnAccount'}
                    change={setCreatingAccount}
                    label={'Create an account?'}
                    disabled={uploading}
                  />
                  {creatingAccount &&
                    <div>
                      <TextControl
                        value={pass}
                        name={'Password'}
                        change={setPass}
                        label={'Create Password'}
                        valid={false}
                        type='password'
                        disabled={uploading}
                      />

                      <TextControl
                        value={confirmPass}
                        name={'confirmPass'}
                        change={setConfirmPass}
                        label={'Confirm Password'}
                        valid={false}
                        type='password'
                        disabled={uploading}
                      />
                    </div>
                  }
                </div>}

              {!allDigital &&
                <div>
                  <h4>Shipping Address</h4>
                  <AddressForm address={address} setAddress={setAddress} uploading={uploading} />
                </div>
              }

              {address.country !== 'US' && address.country !== '' &&
                <TextControl
                  value={phone}
                  name={'Phone'}
                  change={setPhone}
                  label={'Phone Number'}
                  valid={false}
                  type='text'
                  disabled={uploading}
                />
              }


              <BinaryControl
                value={giftOrder}
                change={setGiftOrder}
                label={'Is this order a gift?'}
                help={`If it is, we won't include a receipt or packing slip in the shipment.`}
              />
            </div>
          }

          {!user.id && loggingIn &&
            <LoginForm cancel={() => { setLoggingIn(false) }} />
          }

          {(mode === 'wholesale' || mode === 'library' || user.admin) &&
            <div>
              <TextControl
                value={ref}
                change={setRef}
                name={'Purchase Order Number'}
                label={'PO#'}
                valid={false}
                type='text'
                disabled={uploading}
              />

              <BinaryControl
                value={inPerson}
                change={setInPerson}
                label={'Order in person?'}
                disabled={uploading}
              />

              {!eventOrder && <BinaryControl
                value={collect}
                change={setCollect}
                label={'Pre-pay for an additional 8% off your order'}
                disabled={uploading}
              />}
              <BinaryControl
                value={eventOrder}
                change={setEventOrder}
                label={'Is this order for an event?'}
                disabled={uploading}
                help='Event orders are returnable within 30 days of event date'
              />
              {eventOrder && <DateControl
                value={eventDate}
                change={setEventDate}
                label='Date for event'
                disabled={uploading}
                help={eventDate ? `Invoice will be due ${moment(eventDate).add(30, 'days').format('LL')}` : ''}
              />}
            </div>
          }

          <div className='discountCode flex-layout flex-layout-small'>
            <div className='flex-three'>
              <TextControl
                value={code}
                change={setCode}
                name={'Discount Code'}
                label={'Discount Code'}
                valid={false}
                type='text'
                disabled={uploading || coupon._id}
              />
              {codeStatus === 'success' && coupon.discountType !== 'shipping' && <div className='roomBelowMedium'><i className='fa-solid fa-check'></i> {coupon.discountType === 'dollar' && '$'}{coupon.discountValue}{coupon.discountType === 'fixed' && '%'} off code applied!</div>}
              {codeStatus === 'success' && coupon.discountType === 'shipping' &&
                <div className='roomBelowMedium'><i className='fa-solid fa-check'></i> Free shipping coupon applied!</div>
              }
              {codeStatus === 'invalid' && <div className='roomBelowMedium'><i className='fa-solid fa-x'></i> Invalid code</div>}
            </div>
            <div className='flex-one' style={{ marginTop: '24px' }}>
              <button disabled={coupon._id ? true : false} className='buttonPrimary-small' onClick={checkCoupon}>Apply</button>
            </div>
          </div>

          <GiftCardCheck giftCardBalance={giftCardBalance} setGiftCardBalance={setGiftCardBalance} total={getCartTotal() + selectedRate.asterismRate} />
          <hr />
          {packages > 1 &&
            <BinaryControl
              value={consolidateShipments}
              change={setConsolidateShipments}
              label={'Ship all books at once to save on shipping?'}
              help={`Because your order contains items that are either preorders or backorders, there will be at least ${packages} packages. The latest consolidated ship date is estimated to be ${moment(latestShip).format('LL')}`}
            />
          }
          {rates && <h4>Choose a Shipping Option</h4>}
          {rates.length === 0 && (!address.country || !address.postalCode) && <div><em>Please select a country and enter a postal code to see shipping rates.</em></div>}
          {rates && <ShippingSelector rates={rates} selectedRate={selectedRate} setSelectedRate={setSelectedRate} />
          }
          <div className='cartBreakdown'>
            <div className='cartBreakdown__line'><strong>Subtotal:</strong> ${(getCartTotal()).toFixed(2)}</div>
            {(mode === 'wholesale' || isJosh) && collect && <div className='cartBreakdown__line'><strong>Prepay Discount:</strong> - ${((selectedRate.asterismRate + total) * .08).toFixed(2)}</div>}
            <div className='cartBreakdown__line'><strong>Shipping:</strong> {fetching ? 'Checking rates...' : selectedRate.asterismRate ? `$${selectedRate.asterismRate.toFixed(2)}` : `--`}</div>
            {giftCardBalance > 0 && <div className='cartBreakdown__line'><strong>Gift Card Balance:</strong> ${giftCardBalance.toFixed(2)}</div>}

            {((mode === 'wholesale' || isJosh) && collect)
              ? < div className='cartBreakdown__total'><strong>Total:</strong> ${(((getCartTotal()) * .92) + selectedRate.asterismRate).toFixed(2)}</div>
              : < div className='cartBreakdown__total'><strong>Total:</strong> ${(getCartTotal() + selectedRate.asterismRate).toFixed(2)}</div>
            }
            <div className='cartBreakdown__extra'>Note: We will collect any applicable sales taxes at the next step.</div>
          </div>

          <div className='cartButtons'>
            <button className='cartButton' disabled={uploading || loggingIn || fetching} onClick={() => { checkout() }}>{(mode === 'wholesale' || mode === 'library' || customerData._id) && !collect ? 'Place Order' : 'Checkout'}</button>
          </div>
          {/*mode !== 'wholesale' && mode !== 'library' &&
            <PayPal total={getCartTotal()} items={preparedItems} shipping={shipping} setUploading={setUploading} disabled={!email || uploading || (!isCartAllDigital(cart) && !validateAddress(address))} />
          */}
        </div>
      }
      {uploading && <div>Preparing your checkout session!</div>}



    </div >
  )
}