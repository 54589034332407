import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { analyticsEvent, asaEvent, canOrderProduct, makeRequest, prepareAuthorList, returnPrice, updatedCart, wishlistChange } from '../../Utils';
import './style.css';
import moment from 'moment';
import showdown from 'showdown';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { cartState, messageState, modeState, pageviewState, sessionState, userState } from '../../atoms';
const converter = new showdown.Converter();

export default function BlogPost() {
  const [post, setPost] = useState({});
  const user = useRecoilValue(userState);
  let [searchParams, setSearchParams] = useSearchParams();
  const [preview, setPreview] = useState(false);

  const { slug } = useParams();

  const getPost = async (id) => {
    const url = id ? `blog?id=${id}` : `blog?slug=${slug}`;
    let data = await makeRequest(url);
    setPost(data);
    document.title = `${data.title} | Asterism Books`;
    document.querySelector('meta[name="description"]').setAttribute('content', data.summary);
  }

  useEffect(() => {
    if (slug === 'preview') setPreview(true);
    const id = searchParams.get('id');
    getPost(id);
  }, [slug])

  if (!post._id) {
    return <></>
  }



  return (
    <div className='blogPage'>
      {preview && <div className='blogPreviewMessage'>You are currently previewing a private post.</div>}
      <div className='blogHeader'>
        <div className='blogLink' ><a href={'/blog'}>Asterism Blog</a> | {post.tags && post.tags.map((tag) => { return tag.label }).join(', ')} </div>
        <h1 className='blogTitle'>{post.title}</h1>
        <div className='blogMeta'>
          {post.author} | {moment(post.publishDate).format('LL')} {user.admin && <span>| <Link to={`/admin/post/${post._id}`}>Edit</Link></span>}
        </div>
      </div>
      <div className='blogImage'>
        {post.image && <img src={post.image['900'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com')} alt='blog cover' />}
      </div>
      <div className='blogBody'>
        <BlogBody blocks={post.blocks} />
      </div>
    </div>
  )
}

export function BlogBody({ blocks }) {
  const formatText = (text) => {
    let html = converter.makeHtml(text);
    return html
  }

  return (
    <>
      {blocks.map((block, index) => {
        if (block.type === 'text') {
          return (
            <div className='blogText' dangerouslySetInnerHTML={{ __html: formatText(block.content) }} />
          );
        } else if (block.type === 'quote') {
          return (<div className='blogQuoteContainer'>
            <div className='blogQuote' dangerouslySetInnerHTML={{ __html: formatText(block.text) }} />
            <div className='blogQuoteSource'>{block.source}</div>
          </div>);
        } else if (block.type === 'book') {
          return (<BookPreview id={block.id} />)
        } else if (block.type === 'image' && block.images.length > 1) {
          return (<ImageGallery images={block.images} />)
        } else if (block.type === 'image' && block.images.length === 1) {
          return (<div className='singleImage'>
            <div className='singleImageContainer'>
              <img src={block.images[0].sizes['900'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com')} alt={block.images[0].alt ?? block.images[0].caption} />
            </div>
            <div className='blogGalleryCaption'>
              {block.images[0].caption} {block.images[0].credit ? `| ${block.images[0].credit}` : ''}
            </div>
          </div>)
        } else {
          return (<></>)
        }
      })
      }
    </>
  )
}

function BookPreview({ id }) {
  const [product, setProduct] = useState({});
  const mode = useRecoilValue(modeState);
  const [cart, setCart] = useRecoilState(cartState);
  const setMessage = useSetRecoilState(messageState);
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);
  const user = useRecoilValue(userState);

  const getProduct = async () => {
    let data = await makeRequest(`product?id=${id}`);
    setProduct(data);
  }

  const addToCart = () => {
    setCart(updatedCart(cart, product));
    setMessage({ type: 'success', label: 'Added to Cart', text: `<strong>${product.title}</strong> has been added to your cart!`, img: product.cover['300'], temp: false, cart: true });
    analyticsEvent('addToCart', { product: product._id, location: 'productList', mode: mode });
    asaEvent({
      session: session.id,
      category: 'cart',
      action: 'addToCart',
      label: product._id,
      locationOne: ``,
      locationTwo: 'productCard_blog',
      locationThree: 'button',
      testgroup: session.group,
      pagetype: pageview.pagetype,
      pageview: pageview.id,
      campaign: session.campaign,
      value: 1
    });
  }

  const navigate = useNavigate();

  useEffect(() => {
    getProduct()
  }, [id]);

  if (!product._id) {
    return (<></>)
  }

  return (
    <div className='blogBookCard'>
      <div className='blogBookCardImage'>
        <img src={product.cover['300'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com')} alt={`Book cover for ${product.title}`} />
      </div>
      <div>
        <div className='blogBooKCardMeta'>{moment(product.publicationDate).format('LL')} | <Link to={`/publisher/${product.publisherData.slug}`}>{product.publisherData.name}</Link></div>
        <Link to={`/product/${product.slug}`}><h3 className='blogBookCardTitle'>{product.title}</h3></Link>
        <div>{prepareAuthorList(product)}</div>
        <div className='productItemPrice blogBookCardPrice'>
          <div className='productItemPrice__retail'>${returnPrice(product, mode).toFixed(2)}</div>
          {canOrderProduct(product, mode) && mode === 'wholesale' && <div className='productItemPrice__wholesale'>{product.prices.wholesaleDiscount}% off</div>}
        </div>
        <div className='productItem__controls'>
          <button className='productSmallAddToCart' disabled={!canOrderProduct(product, mode)} onClick={addToCart}><i className='fa-solid fa-bag-shopping'></i> Add</button>
          <button className='productSmallWishlist-inline' onClick={() => {
            asaEvent({
              session: session.id,
              category: 'wishlist',
              action: 'click',
              label: product._id,
              locationOne: '',
              locationTwo: '',
              locationThree: 'button',
              testgroup: session.group,
              pagetype: pageview.pagetype,
              pageview: pageview.id,
              campaign: session.campaign,
              value: 1
            });
            if (user.id) {
              wishlistChange(user.id, product._id, true);
              setMessage({ type: 'success', label: 'Added to Wishlist', text: `<strong>${product.title}</strong> has been added to your wishlist!`, temp: true })
            } else {
              navigate(`/login?wishlist=${product._id}`);
            }
          }}><i className='fa-solid fa-bookmark'></i></button>
        </div>
      </div>
    </div>
  )
}


function ImageGallery({ images }) {
  const [selected, setSelected] = useState(0);

  return (
    <div className='blogGallery'>
      <div className='blogGallerySlide'>
        <button className='blogGalleryNav blogGalleryNav-left' onClick={() => { setSelected(selected === 0 ? images.length - 1 : selected - 1) }}><i className='fa-solid fa-chevron-left'></i></button>
        <img src={images[selected].sizes['900'].replace('https://asterism-files.s3.us-east-1.amazonaws.com', 'https://files.asterismbooks.com')} alt={images[selected].alt ?? images[selected].caption} />
        <button className='blogGalleryNav blogGalleryNav-right' onClick={() => { setSelected(selected === images.length - 1 ? 0 : selected + 1) }}><i className='fa-solid fa-chevron-right'></i></button>
      </div>
      <div className='blogGalleryCaption'>
        {images[selected].caption} {images[selected].credit ? `| ${images[selected].credit}` : ''}
      </div>
    </div>
  )
}