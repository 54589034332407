import { formatISODate, makeRequest, returnPrice, isPreorder, getEffectiveInventory, returnISODate, getActualInventory, isDigitalItem, isGiftCard } from "../../Utils";
import moment from 'moment';

const adjustDateForWeekend = (maybeDay) => {
  try {
    let day = maybeDay.weekday();
    if (day === 0) {
      return maybeDay.add('1', 'days')
    } else if (day === 7) {
      return maybeDay.add('2', 'days')
    } else {
      return maybeDay;
    }
  } catch (e) {
    return moment();
  }
}

export const isCartAllDigital = (cart) => {
  let digital = true;
  Object.values(cart).forEach((product) => {
    if (!isDigitalItem(product.data.format)) {
      digital = false;
    }
  });
  return digital;
}

export const hasGiftCard = (cart) => {
  let giftCard = false;
  Object.values(cart).forEach((product) => {
    if (isGiftCard(product.data.format)) {
      giftCard = true;
    }
  });
  return giftCard;
}

export const isCartAnyDigital = (cart) => {
  let digital = false;
  Object.values(cart).forEach((product) => {
    if (isDigitalItem(product.data.format)) {
      digital = true;
    }
  });
  return digital;
}

/**
 * 
 * @param {object} product Product data
 * @param {string} mode Order mode
 * @param {number} quantity Number of products
 * @returns String representing ISO String of ship date
 */
const approximateShipDate = (product, mode, quantity) => {
  let date = ''

  let effective = getEffectiveInventory(product, mode);
  let isBackorder = !isPreorder(product) && !getActualInventory(product);

  if (isBackorder) { // On backorder, explicitly
    let maybeDate = moment().add(21, 'days');
    let realDate = adjustDateForWeekend(maybeDate);
    date = realDate.toISOString();
  } else if (effective >= quantity && !isPreorder(product)) { // We have it and it's out now
    let maybeDate = moment();
    let realDate = adjustDateForWeekend(maybeDate);
    date = realDate.toISOString();
  } else if (effective >= quantity && isPreorder(product)) { // Have it and it's a preorder
    let maybeDate = moment().add(10, 'days').isAfter(moment(product.publicationDate))
      ? moment()
      : moment(product.publicationDate).subtract(5, 'days');
    let realDate = adjustDateForWeekend(maybeDate);
    date = realDate.toISOString();
  } else if (isPreorder(product)) { // Don't have it and it's a preorder so assume pub day
    let maybeDate = moment(product.publicationDate);
    let realDate = adjustDateForWeekend(maybeDate);
    date = realDate.toISOString();
  } else { // Else it's on backorder and we best be able to get it within 3 weeks
    let maybeDate = moment().add(21, 'days').toISOString();
    let realDate = adjustDateForWeekend(maybeDate);
    date = realDate.toISOString();
  }

  return date;
}

const shouldFulfillNow = (inStock, product) => {
  if (!inStock) {
    return false;
  } else if (inStock && !isPreorder(product)) {
    return true;
  }
  else {
    return moment(product.publicationDate).subtract(10, 'days').isBefore(moment());
  }
}

/**
 * This is the big important function
 * @param {number} total The total amount in the cart
 * @param {*} cart The cart data
 * @param {number} shipping Calculated total for shipping
 * @param {number} ounces Total weight of books in cart
 * @param {string} mode Either retail | wholesale | library | publisher
 * @param {boolean} consolidate Whether or not to consolidate shipments
 * @param {string} country Two-letter country code
 * @param {number} setPrice If the product has a set price outside of the normal modes calculation (only works for single-book orders)
 * @returns 
 */
export const prepareShipmentsAndSplits = (total, cart, shipping, ounces, mode, consolidate, country, coupon, collect, setPrice = null, partnerCode = '') => {
  let items = Object.values(cart);
  let payments = {
    asterism: 0,
  };

  const allShipments = {};

  if (partnerCode) { // New idea!
    mode = 'wholesale';
  }

  items.forEach((item) => {
    let product = item.data;
    if (!payments[product.publisher]) {
      payments[product.publisher] = 0;
    }
    let count = item.count;
    let weight = product.dimensions?.weight || 10;
    let price = setPrice ?? returnPrice(product, mode, coupon, mode === 'wholesale' && collect, item.isUsed);
    let shipDateTime = approximateShipDate(product, mode, count);
    let shipDate = returnISODate(shipDateTime);
    let fulfillNow = shouldFulfillNow(count <= product.inventory.asterism, product);
    let status = fulfillNow ? 'Ready' : isPreorder(product) ? 'Preorder' : 'Backorder';
    payments[product.publisher] += price * count * .76;

    if (!isDigitalItem(product.format)) {
      allShipments[`${shipDate}`] = allShipments[`${shipDate}`] || { items: {}, weight: 0, status: status };
      allShipments[`${shipDate}`].items[product._id] = count;
      allShipments[`${shipDate}`].weight += (count * weight);
    }
  });


  let shipments = allShipments;
  shipments = consolidateShipments(shipments, consolidate || (mode !== 'wholesale' && country !== 'US'));
  let shipmentDates = Object.keys(shipments);
  let totalPayouts = getTotalPayouts(payments);
  let asterismCut = total - totalPayouts;
  payments.asterism = payments.asterism ? payments.asterism : 0;
  payments.asterism += asterismCut;

  return {
    payments: payments,
    latestShipping: shipmentDates[shipmentDates.length - 1],
    shipments: formatShipments(shipments),
    shippingSplit: { 'asterism': shipping },
  }
}

/**
 * Combines near ship dates
 * @param {object} shipments 
 * @returns {object} consolidated shipments
 */
const consolidateShipments = (shipments, consolidate = false) => {
  // Are these dates close to each other? If so, combine the shipments!
  let dates = Object.keys(shipments);
  let pseudoShipments = dates.map((date, i) => {
    let shipment = Object.values(shipments)[i];
    shipment.date = date;
    return shipment;
  })
  pseudoShipments = pseudoShipments.sort((a, b) => { return a.date.localeCompare(b.date) });

  let toDelete = [];
  for (let i = 0; i < pseudoShipments.length - 1; i++) {
    let thisDate = moment(pseudoShipments[i].date);
    let nextDate = moment(pseudoShipments[i + 1].date);
    if (nextDate.diff(thisDate, 'days') < 10 || consolidate) {
      // Combine these and.... do what?
      pseudoShipments[i + 1] = combineShipments(pseudoShipments[i], pseudoShipments[i + 1]);
      toDelete.push(i);
    }
  }
  pseudoShipments = pseudoShipments.filter((shipment, i) => {
    return toDelete.indexOf(i) === -1;
  })

  let returnShipments = {};
  pseudoShipments.forEach((shipment) => {
    returnShipments[shipment.date] = shipment;
  })
  return returnShipments;
}

/**
 * Combines two shipments
 * @param {object} one earlier shipment
 * @param {object} two later shipment
 * @returns {object} combined shipment object
 */
const combineShipments = (one, two) => {
  let combined = { ...two };
  combined.weight += one.weight;
  combined.items = { ...one.items, ...two.items };
  return combined;
}

/**
 * 
 * @param {*} shipments 
 * @returns 
 */
const formatShipments = (shipments) => {
  let formatted = [];
  let dates = Object.keys(shipments);
  let shipmentData = Object.values(shipments);
  for (let i = 0; i < dates.length; i++) {
    formatted.push({
      from: 'asterism',
      items: shipmentData[i].items,
      asterism: shipmentData[i].asterism,
      weight: shipmentData[i].weight,
      status: shipmentData[i].status,
      shipDate: dates[i],
      created: formatISODate(new Date()),
      tracking: ''
    })
  }
  return formatted;
}

export const getTotalPayouts = (payments) => {
  return Object.values(payments).reduce((partialSum, a) => partialSum + a, 0);
}

export const prepareItems = (cart, mode, setPrice = null, prepay = false, coupon = null) => {
  let items = { ...cart };
  let itemIds = Object.keys(items);
  itemIds.forEach((id) => {
    let product = items[id].data;

    let salePrice = setPrice ?? returnPrice(product, mode, coupon, prepay, items[id].isUsed);
    let item = {
      title: `${product.title}`,
      authors: `${product.authors.join(', ')}`,
      isPreorder: isPreorder(product),
      quantity: items[id].count,
      listPrice: product.prices.retail,
      salePrice: salePrice,
      publisher: product.publisher,
      digital: isDigitalItem(product.format),
      giftCard: isGiftCard(product.format),
      used: items[id].isUsed,
      oneOff: items[id].oneOff,
    }
    items[id] = item;
  })
  return items;
}

export const refreshCartData = async (cart, mode, admin) => {
  const ids = Object.keys(cart);
  const items = {};

  for (let i = 0; i < ids.length; i++) {
    try {
      const data = await makeRequest(`product?id=${ids[i]}`, 'GET');
      let inventory = getEffectiveInventory(data, admin ? 'wholesale' : mode);
      items[ids[i]] = {
        count: cart[ids[i]].count >= inventory ? inventory : cart[ids[i]].count,
        data: data
      }
      if (!isDigitalItem(data.format) && items[ids[i]].count === 0 && !admin) {
        delete items[ids[i]];
      }
    } catch (e) {
      delete items[ids[i]];
    }
  }
  return items;
}