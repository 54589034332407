import React, { useState } from 'react';
import styles from './menu.module.css';
import { Link } from 'react-router-dom';
import { asaEvent, slugify } from '../../Utils';
import { useRecoilValue } from 'recoil';
import { pageviewState, sessionState } from '../../atoms';

const menuConfig = [
  {
    heading: 'Quick Links',
    items: [
      {
        title: 'Weekly Bestsellers',
        link: '/bestsellers'
      },
      {
        title: 'New Releases',
        link: '/new-books'
      },
      {
        title: 'Browse All Titles',
        link: '/all-books'
      },
      {
        title: 'Upcoming Titles',
        link: '/upcoming-books'
      },
      {
        title: 'Book Blog',
        link: '/blog'
      },
      {
        title: 'eBooks',
        link: '/ebooks'
      }, {
        title: 'On Sale Now',
        link: '/all-books?onSale=true'
      },
      {
        title: 'Our Publishers',
        link: '/our-publishers'
      }
    ]
  },
  {
    heading: 'Browse by Category',
    items: [
      {
        title: 'Fiction',
        link: '/all-books?categories=fiction'
      },
      {
        title: 'Poetry & Poetics',
        link: '/all-books?categories=poetry%2Cpoetics',

      },
      {
        title: 'Essays & Criticism',
        link: '/all-books?categories=essays%2Ccriticism',
      },
      {
        title: 'Art Books',
        link: '/all-books?categories=art-visual%2Cartist-book',
      },
      {
        title: 'Translation',
        link: '/all-books?categories=translation',
      },
      {
        title: 'Short Stories',
        link: '/all-books?categories=short-stories',
      },
      {
        title: 'Memoir',
        link: '/all-books?categories=translation',
      },
      {
        title: 'Horror',
        link: '/all-books?categories=horror',
      },
      {
        title: 'Queer Stories',
        link: '/all-books?categories=queer-lit%2Cqueer-studies',
      },
      {
        title: 'Magazines & Journals',
        link: '/all-books?categories=art-visual%2Cartist-book',
      },
      {
        title: 'More',
        link: '/all-books',

      }
    ]
  },
  {
    heading: 'Info',
    items: [
      {
        title: 'For prospective publishers',
        link: '/for-publishers'
      },
      {
        title: 'For booksellers',
        link: '/for-retailers'
      },
      {
        title: 'For librarians',
        link: '/for-libraries'
      },
      {
        title: 'About Us',
        link: '/about'
      },
      {
        title: 'Meet the Team',
        link: '/meet-the-team'
      }
    ]
  },
  {
    heading: 'Contact',
    items: [
      {
        title: 'Customer Support',
        link: '/customer-support'
      },
      {
        title: 'Contact',
        link: '/contact'
      },
    ]
  },
]

export default function MegaMenu({ open, close }) {
  const session = useRecoilValue(sessionState);
  const pageview = useRecoilValue(pageviewState);

  const menuClick = (label) => {
    asaEvent({
      session: session.id,
      category: 'menu',
      action: 'click',
      label: 'newMenu',
      locationOne: label,
      locationTwo: '',
      locationThree: '',
      testgroup: session.group,
      pagetype: pageview.pagetype,
      pageview: pageview.id,
      campaign: session.campaign,
      value: 1
    });
  }

  return (<div className={`${styles.megaMenu} ${open && styles.megaMenuOpen}`}>
    {menuConfig.map((section) => {
      return (<div key={section.heading} className={styles.megaMenuSection}>
        <div className={styles.megaMenuItem}><h3>{section.heading}</h3></div>
        {section.items.map((item) => {
          return (
            <div className={styles.megaMenuItem} key={item.title}>
              <Link to={item.link} onClick={() => { close(); menuClick(slugify(item.title)) }}>{item.title}</Link></div>
          )
        })}
      </div>)
    })}
  </div>)
}