import React from 'react';
import moment from 'moment';
import { Asendia, DHL, Postal, UPS } from '../../icons/shipping';
import styles from './rates.module.css';

export default function ShippingSelector({ rates, selectedRate, setSelectedRate }) {
  return (
    <div className={styles.shippingRates}>
      {rates.map((rate) => {
        return (
          <div className={`${styles.shippingRate} ${rate.service === selectedRate.service && styles.selectedRate}`} onClick={() => { setSelectedRate(rate) }}>
            <div>
              <div className={styles.shippingPrice}><strong>${rate.asterismRate.toFixed(2)}</strong></div>
              <div>{rate.carrierFriendly} {rate.service}</div>
              <div className={styles.shippingEstimate}>Est. Delivery {moment().add(rate.delivery_days, 'days').format('MMMM Do')}</div>
            </div>
            <div className={styles.shippingIcon}>
              {rate.carrierFriendly === 'Asendia' && <Asendia />}
              {rate.carrierFriendly === 'DHL' && <DHL />}
              {rate.carrierFriendly === 'UPS' && <UPS />}
              {rate.carrierFriendly === 'USPS' && <Postal />}
            </div>
          </div>
        )
      })}
    </div>
  )
}